<template>
  <div>
    <v-dialog v-model="dialog" fullscreen persistent>

      <v-card>

        <v-card-title>
          {{ animator.name }}
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">

          <v-skeleton-loader v-if="isLoading" type="table"/>

          <div v-else>

            <v-card class="rounded-lg mb-5" outlined>
              <v-card-title>

                <span class="fs-16">
                    Pointages
                  </span>

                <v-spacer/>

                <v-btn color="indigo" icon @click="getSales">
                  <v-icon>mdi-table-refresh</v-icon>
                </v-btn>

                <v-btn v-if="showPointing === true" color="indigo" icon @click="showPointing = !showPointing">
                  <v-icon>mdi-minus-circle</v-icon>
                </v-btn>

                <v-btn v-else color="indigo" icon @click="showPointing = !showPointing">
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>

              </v-card-title>

              <v-divider v-if="showPointing"/>

              <v-card-text v-if="showPointing">

                <v-simple-table class="table-border rounded-lg">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-no-wrap">POS</th>

                      <th class="text-no-wrap text-center">
                        <v-icon color="indigo" dense>mdi-calendar</v-icon>
                        Date
                      </th>

                      <th class="text-no-wrap text-center">
                        <v-icon color="indigo" dense>mdi-clock</v-icon>
                        Heure de début
                      </th>

                      <th class="text-no-wrap text-center">
                        <v-icon color="indigo" dense>mdi-image</v-icon>
                        Image de début
                      </th>

                      <th class="text-no-wrap text-center">
                        <v-icon color="indigo" dense>mdi-map-marker-check</v-icon>
                        Position de début
                      </th>

                      <th class="text-no-wrap text-center">
                        <v-icon color="indigo" dense>mdi-clock</v-icon>
                        Heure de fin
                      </th>

                      <th class="text-no-wrap text-center">
                        <v-icon color="indigo" dense>mdi-image</v-icon>
                        Image de fin
                      </th>

                      <th class="text-no-wrap text-center">
                        <v-icon color="indigo" dense>mdi-map-marker-check</v-icon>
                        Position de fin
                      </th>

                      <th class="text-no-wrap"></th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,index) in pointings" :key="index">

                      <td>
                        <v-list-item v-if="item.salepoint" class="pl-0 ml-0 py-4" dense>

                          <v-list-item-avatar v-if="item.salepoint.image" left size="35">
                            <v-img :src="$baseUrl + item.salepoint.image"
                                   class="zoom-pointer"
                                   size="35"
                                   @click="$refs.lightbox.open(item.salepoint.image)"></v-img>
                          </v-list-item-avatar>

                          <v-list-item-avatar v-else
                                              :class="$func.randomColor()"
                                              class="font-weight-medium"
                                              left
                                              size="35"
                          >
                            {{ $func.avatarName(item.salepoint.name) }}
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title v-text="item.salepoint.name"></v-list-item-title>
                            <v-list-item-subtitle class="indigo--text"
                                                  v-text="item.salepoint.code"></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <div v-else>-</div>
                      </td>

                      <td class="text-no-wrap text-center">
                        {{ $func.dateFormat(item.date) }}
                      </td>


                      <td class="grey lighten-3 text-center">
                        {{ $func.hourFormat(item.start_time) }}
                      </td>
                      <td class="grey lighten-3 text-center">
                        <v-avatar rounded>
                          <v-img v-if="item.starting_image"
                                 :src="$baseUrl + item.starting_image"
                                 class="zoom-pointer"
                                 @click="$refs.lightbox.open(item.starting_image)"/>

                          <v-img v-else
                                 :src="require('@/assets/no-image.png')"></v-img>
                        </v-avatar>
                      </td>
                      <td class="grey lighten-3 text-center">
                        <a :href="'https://www.google.com/maps/search/?api=1&query=' + item.starting_latitude + ',' + item.starting_longitude"
                           class="indigo--text text-decoration-none text-no-wrap"
                           target="_blank">
                          {{ item.starting_latitude }} , {{ item.starting_longitude }}
                        </a>
                      </td>

                      <td class="grey lighten-4 text-center">
                        <div v-if="item.end_time">
                          {{ $func.hourFormat(item.end_time) }}
                        </div>
                        <v-icon v-else color="red">mdi-close-circle</v-icon>
                      </td>
                      <td class="grey lighten-4 text-center">

                        <v-avatar v-if="item.ending_image" rounded>
                          <v-img :src="$baseUrl + item.ending_image"
                                 class="zoom-pointer"
                                 @click="$refs.lightbox.open(item.ending_image)"/>
                        </v-avatar>

                        <v-icon v-else color="red">mdi-close-circle</v-icon>

                      </td>
                      <td class="grey lighten-4 text-center">
                        <a v-if="item.ending_latitude && item.ending_longitude"
                           :href="'https://www.google.com/maps/search/?api=1&query=' + item.ending_latitude + ',' + item.ending_longitude"
                           class="indigo--text text-decoration-none text-no-wrap"
                           target="_blank">
                          {{ item.ending_latitude }} , {{ item.ending_longitude }}
                        </a>
                        <v-icon v-else color="red">mdi-close-circle</v-icon>
                      </td>

                      <td class="text-center">
                        <v-btn icon color="primary"
                               @click="[pointingDetailDialog = true,pointingDetails = item.pointing_details]">
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                      </td>

                    </tr>

                    <tr v-if="!pointings.length">
                      <td colspan="9" class="text-center">Aucun enregistrement trouvé!</td>
                    </tr>

                    </tbody>
                  </template>
                </v-simple-table>

              </v-card-text>

            </v-card>


            <div v-if="sales.length">
              <v-card class="rounded-lg" outlined>
                <v-card-title>
                  <span class="fs-16">
                    Ventes
                  </span>
                  <v-spacer/>

                  <v-btn color="indigo" icon @click="getSales">
                    <v-icon>mdi-table-refresh</v-icon>
                  </v-btn>

                  <v-btn v-if="showSales=== true" color="indigo" icon @click="showSales = !showSales">
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>

                  <v-btn v-else color="indigo" icon @click="showSales = !showSales">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>

                </v-card-title>

                <v-divider v-if="showSales"/>

                <v-card-text v-if="showSales">
                  <v-simple-table class="table-border rounded-lg">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-no-wrap">ID</th>
                        <th class="text-no-wrap">POS</th>
                        <th class="text-no-wrap">Nom du consommateur</th>
                        <th class="text-no-wrap">Tél du consommateur</th>
                        <th class="text-no-wrap">Âge</th>
                        <th class="text-no-wrap">Produit consomé</th>
                        <th class="text-no-wrap">Produit acheté</th>
                        <th class="text-no-wrap">Type de vente</th>
                        <th class="text-no-wrap">Cadeau</th>
                        <th class="text-no-wrap">Date</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item,index) in sales" :key="index">
                        <td>
                          {{ item.id }}
                        </td>
                        <td>
                          <v-list-item v-if="item.salepoint" class="pl-0 ml-0" dense>

                            <v-list-item-avatar v-if="item.salepoint.image" left size="35">
                              <v-img :src="$baseUrl + item.salepoint.image"
                                     class="zoom-pointer"
                                     size="35"
                                     @click="$refs.lightbox.open(item.salepoint.image)"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-avatar v-else
                                                :class="$func.randomColor()"
                                                class="font-weight-medium"
                                                left
                                                size="35"
                            >
                              {{ $func.avatarName(item.salepoint.name) }}
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title v-text="item.salepoint.name"></v-list-item-title>
                              <v-list-item-subtitle class="indigo--text"
                                                    v-text="item.salepoint.code"></v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <div v-else>-</div>
                        </td>

                        <td>
                          {{ item.customer_name }}
                        </td>

                        <td>
                          {{ item.customer_phone }}
                        </td>

                        <td>
                          {{ item.customer_age }}
                        </td>

                        <td class="text-no-wrap">
                          {{ item.original_product ? item.original_product.name : "-" }}
                        </td>

                        <td class="text-no-wrap">
                          {{ item.campaign_product ? item.campaign_product.name : "-" }}
                        </td>

                        <td class="text-no-wrap">
                          <v-chip small v-if="item.sale_type === 'refusal'"
                                  text-color="red"
                                  class="bg-red-subtle font-weight-medium">
                            Refus
                          </v-chip>
                          <v-chip small v-if="item.sale_type === 'switch'"
                                  text-color="blue"
                                  class="bg-blue-subtle font-weight-medium">
                            Switch
                          </v-chip>
                          <v-chip small v-if="item.sale_type === 'fidelity'"
                                  text-color="success"
                                  class="bg-success-subtle font-weight-medium">
                            Fidélité
                          </v-chip>
                        </td>

                        <td class="font-weight-medium primary--text text-no-wrap">
                          <v-chip v-if="item.gift" color="primary" dark small>
                            {{ item.gift.name }}
                          </v-chip>
                          <v-icon v-else color="red">mdi-close-circle</v-icon>
                        </td>

                        <td class="text-no-wrap indigo--text">
                          <v-icon color="indigo" small>mdi-clock</v-icon>
                          {{ $func.dateFormat(item.date) }} -
                          {{ $func.hourFormat(item.start_time) }} à
                          {{ $func.hourFormat(item.end_time) }}
                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-card-actions v-if="total > 10">

                    <div>
                      <v-select v-model="per_page"
                                :items="[10,20,50,100,200,300,400,500]"
                                :style="{width: '120px'}"
                                dense
                                hide-details
                                label="Ligne par page"
                                outlined></v-select>
                    </div>

                    <v-spacer/>
                    <v-pagination v-if="total>10"
                                  v-model="pagination.current"
                                  :length="pagination.total"
                                  total-visible="6"
                                  @input="onPageChange"
                    ></v-pagination>
                  </v-card-actions>
                </v-card-text>

              </v-card>
            </div>
            <NoResult v-else/>
          </div>

        </v-card-text>

      </v-card>

      <v-dialog width="600" v-model="pointingDetailDialog">
        <v-card>
          <v-card-title>
            Détails de pointage
            <v-spacer></v-spacer>
            <v-icon @click="pointingDetailDialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-8">


            <v-simple-table class="table-border rounded-lg">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-no-wrap">Cadeau</th>
                  <th class="text-no-wrap">Quantité initial</th>
                  <th class="text-no-wrap">Quantité actuelle</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(gift,index) in pointingDetails" :key="index">

                  <td class="text-no-wrap">
                    <div class="d-flex align-center py-2" v-if="gift.gift">
                      <v-avatar class="zoom-pointer"
                                rounded
                                @click="$refs.lightbox.open(gift.gift.image)">
                        <v-img :src="$baseUrl+gift.gift.image"></v-img>
                      </v-avatar>
                      &nbsp;
                      {{ gift.gift.name }}
                    </div>
                  </td>

                  <td class="text-no-wrap">
                    <v-chip :class="$func.randomColor()" class="font-weight-medium" label>
                      {{ gift.initial_qty }}
                    </v-chip>
                  </td>

                  <td class="text-no-wrap">
                    <v-chip :class="$func.randomColor()" class="font-weight-medium" label>
                      {{ gift.actual_qty }}
                    </v-chip>
                  </td>

                </tr>

                <tr v-if="!pointingDetails.length">
                  <td colspan="2" class="text-center">
                    Il n'y a pas de cadeaux dans cette campagne ou ils sont tous de
                    type digitales.
                  </td>
                </tr>

                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>


    </v-dialog>

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>


import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      showPointing: false,
      showSales: true,
      isLoading: false,
      dialog: false,
      animator: {},
      pointings: {},

      sales: [],
      total: 0,
      per_page: 10,
      pagination: {
        current: 1,
        total: 0
      },

      pointingDetailDialog: false,
      pointingDetails: [],
    }
  },
  methods: {
    onPageChange() {
      this.getSales();
    },
    open(animator) {
      this.dialog = true
      this.animator = animator
      this.getSales()
    },
    getSales() {
      this.isLoading = true
      HTTP.get('/campaigns/' + this.animator.pivot.campaign_id + '/sales?page=' + this.pagination.current, {
        params: {
          animator_id: this.animator.id,
          per_page: this.per_page,
        }
      }).then((res) => {
        this.isLoading = false
        this.sales = res.data.data.data
        this.pointings = res.data.pointings

        this.pagination.current = res.data.data.current_page
        this.pagination.total = res.data.data.last_page
        this.total = res.data.data.total

      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  watch: {
    per_page() {
      this.pagination.current = 1
      this.getSales();
    },
  },
}
</script>

<style scoped>

</style>